<div class="order-item-details">
  <h4 *ngIf="campaign.campaignId" class="campaign">
    <span>
      Campaign {{ campaign.campaignId }}
    </span>
    <button *ngIf="showDetailButton"
        [routerLink]="['../../campaigns/', campaign._id]"
        class="button button__primary button--small"
      >
        Details
      </button>
  </h4>
  <h4 *ngIf="!campaign.campaignId">Details</h4>
  <table>
    <tbody>
      <tr>
        <td><b>Name</b></td>
        <td>{{ name }}</td>
      </tr>
      <tr>
        <td><b>Domain</b></td>
        <td>{{ domain }}</td>
      </tr>
      <tr>
        <td><b>Network</b></td>
        <td>{{ network }}</td>
      </tr>
      <tr>
        <td><b>Type</b></td>
        <td>{{ campaignType }}</td>
      </tr>
      <tr>
        <td><b>Ad Language</b></td>
        <td>{{ adLanguage$ | async }}</td>
      </tr>
      <tr>
        <td><b>Ad Category </b></td>
        <td>{{ !!adCategory ? adCategory.value : ''}}</td>
      </tr>
      <tr>
        <td><b>Streams</b></td>
        <td>{{ streams || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Device</b></td>
        <td>{{ devices || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Language</b></td>
        <td>{{ languages || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Country</b></td>
        <td>{{ countries || 'All' }}</td>
      </tr>
      <tr>
        <td><b>City</b></td>
        <td (mouseenter)="resize(true)" (mouseleave)="resize(false)">
          {{ cities$ | async | truncate: [displayLength, ''] }}
          <p *ngIf="displayLength == 300 && displayLimit > 300">...</p>
        </td>
      </tr>
      <tr *ngIf="campaign.geotargeting">
        <td><b>Geotargeting</b></td>
        <td>Up to {{ campaign.geotargeting.radius || '0' }} meters from ({{ campaign.geotargeting.point.coordinates[1] }}, {{ campaign.geotargeting.point.coordinates[0]  }})</td>
      </tr>
      <tr>
        <td><b>Regions</b></td>
        <td>{{ regions$ | async  }}</td>
      </tr>
      <tr>
        <td><b>Gender</b></td>
        <td>{{ getFieldsWithExclude(genders, campaign.gendersExcludeFlag) }}</td>
      </tr>
      <tr>
        <td><b>Age</b></td>
        <td>{{ getFieldsWithExclude(ages, campaign.ageRangeExcludeFlag) }}</td>
      </tr>
      <tr>
        <td><b>Interest</b></td>
        <td>{{ interests || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Intent</b></td>
        <td>{{ intents || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Brand</b></td>
        <td>{{ brands || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Event</b></td>
        <td>{{ events || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Content Language</b></td>
        <td>{{ contentLanguage$ | async }}</td>
      </tr>
      <tr>
        <td><b>Content Category</b></td>
        <td>{{ contentCategory$ | async }}</td>
      </tr>
      <tr>
        <td><b>Audio Channel Types</b></td>
        <td>{{ audioProductTypes || 'All' }}</td>
      </tr>
      <tr>
        <td><b>Date range</b></td>
        <td>{{ dateRanges }}</td>
      </tr>
      <tr>
        <td><b>Play times</b></td>
        <td>
          <span *ngIf="!campaign.playTimes || !campaign.playTimes.length">All week</span>
          <table *ngIf="campaign.playTimes && campaign.playTimes.length">
            <tr *ngFor="let playTime of groupedPlayTimes">
              <td>
                <b>{{ playTime.day }}</b>
              </td>
              <td>{{ playTime.timeRanges }}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td><b>Daily budget</b></td>
        <td>{{ DailyBudget }}</td>
      </tr>

      <tr>
        <td><b>Playout capping</b></td>
        <td>{{ playoutCapping }}</td>
      </tr>
      <tr>
        <td><b>Total cost per play</b></td>
        <td>{{ totalCostPerPlay }}</td>
      </tr>
      <tr>
        <td><b>Total budget</b></td>
        <td>{{ totalBudget }}</td>
      </tr>
      <tr *ngIf="expectedPlayouts >= 0">
        <td><b>Expected playouts</b></td>
        <td>{{ expectedPlayouts }}</td>
      </tr>
    </tbody>
  </table>
</div>
