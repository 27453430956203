import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MaxFloorPrice } from '../../common';

@Component({
  selector: 'max-floor-price-form',
  templateUrl: './max-floor-price-form.component.html',
  styleUrls: ['./max-floor-price-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MaxFloorPriceFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MaxFloorPriceFormComponent,
    },
  ],
})
export class MaxFloorPriceFormComponent implements ControlValueAccessor, Validator {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;

  public form: FormGroup;

  private subscriptions: Subscription[] = [];

  onChangeListener: (value: any) => {};
  onTouchedListener: () => {};
  onValidateListener: () => void;

  ngOnInit() {
    const fb = new FormBuilder();
    this.form = fb.group({
      amount: fb.control(0, [Validators.required, Validators.min(0)]),
      currency: fb.control('USD', [Validators.required, Validators.pattern(/^(USD|GBP|RON|EUR|PLN)$/)]),
    });
    this.form.enable();
    this.form.valueChanges.subscribe(({ amount, currency }) => {
      this.onTouchedListener();
      this.onChangeListener({ amount, currency });
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  writeValue(values: MaxFloorPrice) {
    if (values) {
      this.form.setValue(values, { emitEvent: false });
    }
  }
  registerOnChange(onChangeListener: (value: MaxFloorPrice) => {}) {
    this.onChangeListener = onChangeListener;
  }

  registerOnTouched(onTouchedListener: () => {}) {
    this.onTouchedListener = onTouchedListener;
  }
  validate(): ValidationErrors {
    return ['amount', 'currency'].reduce((errors, fieldName) => {
      const field = this.form.controls[fieldName];
      if (!field.valid) {
        return { ...errors, ...field.errors };
      }
      return errors;
    }, {});
  }
  registerOnValidatorChange(onValidateListener: () => void): void {
    this.onValidateListener = onValidateListener;
  }
}
